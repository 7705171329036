export const TV_SIGNAL_REPORT = "tv_signal_report";
export const SHOW_TV_SIGNAL_REPORT = "show_tv_signal_report";
export const BROWSERS_LIST = "browsers_list";
export const CALENDAR_MONTH = "calendar_month";
export const CALENDAR_MONTH_DATE = "calendar_month_date";
export const CALENDAR_WEEK = "calendar_week";
export const CALENDAR_WEEK_DATE = "calendar_week_date";
export const AVAILABILITY_EDIT = "availability_edit";
export const AVAILABILITY_INDEX = "availability_index";
export const CUSTOMER = "customer";
export const HOME = "home";
export const DIAGNOSE_SERVICE = "diagnose_service";
export const DOCUMENT_FILE_DELETE = "document_file_delete";
export const DOCUMENT_DESTROY = "document_destroy";
export const DOCUMENT_DOWNLOAD = "document_download";
export const DOCUMENT_SHOW_CONTENT = "document_show_content";
export const DOCUMENT_INDEX = "document_index";
export const DOCUMENT_SHOW_THUMBNAIL = "document_show_thumbnail";
export const DSLAM_ADD_SERVICED = "dslam_add_serviced";
export const DSLAM_CLEAR_ERRORS = "dslam_clear_errors";
export const DSLAM_ADD = "dslam_add";
export const DSLAM_DELETE = "dslam_delete";
export const DSLAM_DELETE_CONFIRMED = "dslam_delete_confirmed";
export const DSLAM_DETAIL = "dslam_detail";
export const DSLAM_FETCHDSLAMPORTS = "dslam_fetchdslamports";
export const DSLAM_LIST = "dslam_list";
export const DSLAM_REINIT = "dslam_reinit";
export const DSLAM_REMOVE_SERVICED = "dslam_remove_serviced";
export const DSLAM_REMOVE_SERVICED_CONFIRM = "dslam_remove_serviced_confirm";
export const DSLAM_TOGGLE_SUPERVECTORING_EXCLUSION = "dslam_toggle_supervectoring_exclusion";
export const EXPORT_BUILDINGS = "export_buildings";
export const EXPORT_PROJECTS = "export_projects";
export const IKV_CREATE = "ikv_create";
export const PERSON_CONTRACT_DATA = "person_contract_data";
export const ONT_ASSIGN_PERSON = "ont_assign_person";
export const ONT_ASSIGN_UNIT = "ont_assign_unit";
export const ONT_EDIT = "ont_edit";
export const ONT_FORCE_SYNC = "ont_force_sync";
export const ONT_LIST = "ont_list";
export const PERSON_COMBINE = "person_combine";
export const PERSON_COMBINE_CONFIRM = "person_combine_confirm";
export const PERSON_MOVE_CONFIRM = "person_move_confirm";
export const PERSON_NEW = "person_new";
export const PERSON_NEW_CONTRACT_PARTNER = "person_new_contract_partner";
export const PERSON_DELETE = "person_delete";
export const PERSON_DELETE_CONFIRM = "person_delete_confirm";
export const PERSON = "person";
export const PERSON_MOVE = "person_move";
export const PERSON_SAVED_CONFIRM = "person_saved_confirm";
export const PING = "ping";
export const APP_LOGIN = "app_login";
export const APP_LOGOUT = "app_logout";
export const SERVICE_ADD = "service_add";
export const SERVICE_DELETE = "service_delete";
export const SERVICE_EDIT = "service_edit";
export const SERVICE_OVERVIEW = "service_overview";
export const CARD_ADD_PORT = "card_add_port";
export const SWITCH_ADD = "switch_add";
export const SWITCH_ADD_CARD = "switch_add_card";
export const SWITCH_CARD_DETAILS = "switch_card_details";
export const SWITCH_CARD_DELETE = "switch_card_delete";
export const SWITCH_DETAIL = "switch_detail";
export const SWITCH_DUMP_CARD_CONFIG = "switch_dump_card_config";
export const SWITCH_EDIT = "switch_edit";
export const SWITCH_LIST = "switch_list";
export const SWITCH_PORT_TOGGLE_RESERVED = "switch_port_toggle_reserved";
export const EVALUATION_INDEX = "evaluation_index";
export const TASK_DELETE = "task_delete";
export const TASK_DELETE_DOCUMENT = "task_delete_document";
export const AUFGABE = "aufgabe";
export const AUFGABEN_EXTENDED = "aufgaben_extended";
export const AUFGABEN = "aufgaben";
export const AUFGABE_NEW = "aufgabe_new";
export const USER_SETTINGS_ADD_FORWARDING = "user_settings_add_forwarding";
export const USER_SETTINGS_DELETE_CONFIRM = "user_settings_delete_confirm";
export const USER_SETTINGS_REMOVE_FORWARDING = "user_settings_remove_forwarding";
export const USER_SETTINGS_DELETE = "user_settings_delete";
export const USER_SETTINGS_EDIT_FORWARDING = "user_settings_edit_forwarding";
export const USER_SETTINGS_HOME = "user_settings_home";
export const USER_SETTINGS_HOME_ADMIN = "user_settings_home_admin";
export const ACCESS_RIGHTS_INDEX = "access_rights_index";
export const ACCESS_RIGHTS_PRIVILEGE = "access_rights_privilege";
export const ACCESS_RIGHTS_TOPIC = "access_rights_topic";
export const DELETE_GROUP_CONFIRM = "delete_group_confirm";
export const DELETE_GROUP_MEMBER_CONFIRM = "delete_group_member_confirm";
export const USER_GROUP_CREATE = "user_group_create";
export const ADMIN_USER_DEFAULT = "admin_user_default";
export const DELETE_GROUP = "delete_group";
export const DELETE_GROUP_MEMBER = "delete_group_member";
export const USER_GROUP_EDIT = "user_group_edit";
export const SHOW_EDIT_PRIVILEGES = "show_edit_privileges";
export const SHOW_GROUP = "show_group";
export const ADMIN_INDEX_DEFAULT = "admin_index_default";
export const PRIVILEGE_EDIT = "privilege_edit";
export const PRIVILEGE_LIST = "privilege_list";
export const PRODUCT_CREATE = "product_create";
export const PRODUCT_EDIT = "product_edit";
export const PRODUCT_LIST = "product_list";
export const PROJECT_TYPE_EDIT = "project_type_edit";
export const PROJECT_TYPES_LIST = "project_types_list";
export const SHORTCUT_CREATE = "shortcut_create";
export const SHORTCUT_DELETE = "shortcut_delete";
export const SHORTCUT_EDIT = "shortcut_edit";
export const SHORTCUT_LIST = "shortcut_list";
export const SUBTOPIC_CREATE = "subtopic_create";
export const SUBTOPIC_EDIT = "subtopic_edit";
export const SUBTOPIC_LIST = "subtopic_list";
export const TOPIC_CREATE = "topic_create";
export const TOPIC_EDIT = "topic_edit";
export const TOPIC_LIST = "topic_list";
export const USER_CREATE = "user_create";
export const API_V1_SPRI_EINZELVERTRAGNUMMER = "api_v1_spri_einzelvertragnummer";
export const API_V1_SPRI_LINEID = "api_v1_spri_lineid";
export const API_V1_SPRI_AUFTRAG_NEW = "api_v1_spri_auftrag_new";
export const API_V1_SPRI_AUFTRAG_NEW_SLASH = "api_v1_spri_auftrag_new_slash";
export const API_V1_BUILDING_GET = "api_v1_building_get";
export const API_V1_BUILDING_INFO_GET = "api_v1_building_info_get";
export const API_V1_BUILDING_SEARCH = "api_v1_building_search";
export const API_V1_BUILDING_SEARCH_SUPPLIED_BY = "api_v1_building_search_supplied_by";
export const API_V1_DETECON_DIFF_STREAM = "api_v1_detecon_diff_stream";
export const API_V1_DETECON_MASTER_STREAM = "api_v1_detecon_master_stream";
export const API_V1_DOCUMENT_GET_PROJECT = "api_v1_document_get_project";
export const API_V1_DOCUMENT_LIST = "api_v1_document_list";
export const API_V1_DOCUMENT_CREATE = "api_v1_document_create";
export const API_V1_DOCUMENT_DELETE = "api_v1_document_delete";
export const API_V1_DOCUMENT_POST_PROJECT = "api_v1_document_post_project";
export const API_V1_PERSON_COUNT = "api_v1_person_count";
export const API_V1_PERSON_UPDATE = "api_v1_person_update";
export const API_V1_PERSON_SEARCH = "api_v1_person_search";
export const API_V1_PERSON_GET = "api_v1_person_get";
export const API_V1_TASK_SEARCH = "api_v1_task_search";
export const API_V1_TOPIC_LIST_TREE = "api_v1_topic_list_tree";
export const API_V1_UNIT_UPDATE = "api_v1_unit_update";
export const API_V1_AVATAR_GET = "api_v1_user_avatar_get";
export const API_V1_AVATAR_PUT = "api_v1_user_avatar_put";
export const API_V1_AVATAR_DELETE = "api_v1_user_avatar_delete";
export const API_V1_UNIT_LIST = "api_v1_unit_list";
export const API_V1_UNIT_ADD = "api_v1_unit_add";
export const API_V1_UNIT_DELETE = "api_v1_unit_delete";
export const API_V1_UNIT_SERVICES_LIST = "api_v1_unit_service_list";
export const API_V1_UNIT_VERIFY = "api_v1_unit_verify";
export const API_V1_UNIT_REUPLOAD_LABEL = "api_v1_unit_reupload_label";
export const API_V1_USER_PRIVILEGE_GET = "api_v1_user_privilege_get";
export const API_V1_USER_PRIVILEGE_LIST = "api_v1_user_privilege_list";
export const API_V1_AVAILABILITY_STREET_SEARCH = "api_v1_availability_street_search";
export const API_V1_AVAILABILITY_BUILDING_GET = "api_v1_availability_building_get";
export const API_V1_PRIVILEGE_HISTORY_USER_GET = "api_v1_privilege_history_user_get";
export const API_V1_PRIVILEGE_HISTORY_TOPIC_GET = "api_v1_privilege_history_topic_get";
export const API_V1_PRIVILEGE_HISTORY_TOPIC_ADD = "api_v1_privilege_history_topic_add";
export const API_V1_PRIVILEGE_HISTORY_SUBTOPIC_GET = "api_v1_privilege_history_subtopic_get";
export const API_V1_PRIVILEGE_HISTORY_READWRITE = "api_v1_privilege_history_readwrite";
export const API_V1_PRIVILEGE_HISTORY_ALL = "api_v1_privilege_history_all";
export const API_V1_PRIVILEGE_HISTORY_SUBTOPIC_ADD = "api_v1_privilege_history_subtopic_add";
export const API_V1_PRIVILEGE_HISTORY_REMOVE = "api_v1_privilege_history_remove";
export const API_V1_PROJECT_CALCULATION_CONCEPT = "api_v1_project_calculation_concept";
export const API_V1_PROJECT_CONCEPT_ADDITIONAL_INFO_GET = "api_v1_project_concept_additional_info_get";
export const API_V1_PROJECT_CONCEPT_PRESELECTION_DELETE = "api_v1_project_concept_preselection_delete";
export const API_V1_PROJECT_CONCEPT_PRESELECTION_SAVE = "api_v1_project_concept_preselection_save";
export const API_V1_PROJECT_CONCEPT_PRESELECTION_GET = "api_v1_project_concept_preselection_get";
export const BUILDING_DELETE_DOCUMENT = "building_delete_document";
export const BUILDING_FLATS = "building_flats";
export const BUILDING_DETAILS_CONFIRM = "building_details_confirm";
export const FASERMGMT = "fasermgmt";
export const BUILDING_HISTORY = "building_history";
export const BUILDING_DETAILS = "building_details";
export const BUILDING_PERSON_SEARCH = "building_person_search";
export const BUILDING_SEARCH = "building_search";
export const BUILDING_INDEX = "building_index";
export const BUILDING_INDEX_EDIT_NEWS = "building_index_edit_news";
export const BUILDING_LIST = "building_list";
export const BUILDING_SET_SUPPLIED_BY = "building_set_supplied_by";
export const UNIT_EDIT = "unit_edit";
export const UNIT_DETAIL = "unit_detail";
export const ADDRESSBOOK_LIST = "addressbook_list";
export const PROJECT_CALCULATION_EDIT = "project_calculation_edit";
export const CALCULATION_EDIT = "calculation_edit";
export const CONCEPT_COPY = "concept_copy";
export const PROJECT_CONCEPT_NEW = "project_concept_new";
export const CONCEPT_DOCUMENT_DELETE = "concept_document_delete";
export const CONCEPT_EDIT = "concept_edit";
export const PROJECT_CONCEPT_EDIT = "project_concept_edit";
export const CONCEPT_INFRASTRUCTURE_EDIT = "concept_infrastructure_edit";
export const CONCEPT_INHOUSE_EDIT = "concept_inhouse_edit";
export const CONCEPT_PRESERVICE_EDIT = "concept_preservice_edit";
export const CONCEPT_RT_EDIT = "concept_rt_edit";
export const CONCEPT_JSON = "concept_json";
export const PROJECT_FIBER_PATH_NEW = "project_fiber_path_new";
export const FIBER_PATH_DELETE = "fiber_path_delete";
export const FIBER_PATH_DELETE_CONFIRM = "fiber_path_delete_confirm";
export const FIBER_PATH_EDIT = "fiber_path_edit";
export const PROJECT_FIBER_PATH = "project_fiber_path";
export const PROJECT_BUILDING_ADD = "project_building_add";
export const PROJECT_CUSTOMER_ADD = "project_customer_add";
export const PROJECT_INVOICERECIPIENT_ADD = "project_invoiceRecipient_add";
export const PROJECT_PARTNER_ADD = "project_partner_add";
export const PROJECT_NEW = "project_new";
export const PROJECT_BUILDING_DELETE = "project_building_delete";
export const PROJECT_EDIT = "project_edit";
export const PROJECT_LIST = "project_list";
export const PROJECT_EXPORT = "project_export";
export const PROJECT_CUSTOMER_DELETE = "project_customer_delete";
export const PROJECT_LOCATION = "project_location";
export const PROJECT_SUMMARY = "project_summary";
export const PROJECT_TYPE_PRODUCTS_JSON = "project_type_products_json";
export const CALCULATION_OFFER_EDIT = "calculation_offer_edit";
export const OFFER_EDIT = "offer_edit";
export const PROJECT_OFFER = "project_offer";
export const ORDER_TIMELINE_EDIT = "order_timeline_edit";
export const PROJECT_TIMELINE_INDEX = "project_timeline_index";
export const REPORT_ADDRESSES_EXPORT = "report_addresses_export";
export const REPORT_ADDRESSES = "report_addresses";
export const APL_ANALYSIS = "apl_analysis";
export const BANDWIDTH_EXPORT = "bandwidth_export";
export const BANDWIDTH_REPORT = "bandwidth_report";
export const EXPORT_PEOPLE_BY_BANDWIDTH = "export_people_by_bandwidth";
export const SHOW_PEOPLE_BY_BANDWIDTH = "show_people_by_bandwidth";
export const BUILDING_EXPANSION_TYPE_REPORT = "building_expansion_type_report";
export const DETECON_SYNC_SESSION_LOG_DELETE = "detecon_sync_session_log_delete";
export const DETECON_SYNC_SESSION = "detecon_sync_session";
export const DSLAM_REPORT = "dslam_report";
export const REPORT_INDEX = "report_index";
export const REPLICA_DOWNLOAD = "replica_download";
export const REPLICA_REPORT_INDEX = "replica_report_index";
export const POP_MAP = "pop_map";
export const POP_GEO_JSON = "pop_geo_json";
export const CLUSTER_GEO_JSON = "cluster_geo_json";
export const BUILDING_GEO_JSON = "building_geo_json";
export const DOCUMENT_TAG_LIST = "document_tag_list";
export const DOCUMENT_TAG_EDIT = "document_tag_edit";
export const DOCUMENT_TAG_CREATE = "document_tag_create";
export const DOCUMENT_TAG_DELETE = "document_tag_delete";
export const API_GENID = "api_genid";
export const API_ERRORS = "api_errors";
export const API_VALIDATION_ERRORS = "api_validation_errors";
export const API_ENTRYPOINT = "api_entrypoint";
export const API_DOC = "api_doc";
export const API_GRAPHQL_ENTRYPOINT = "api_graphql_entrypoint";
export const API_GRAPHQL_GRAPHIQL = "api_graphql_graphiql";
